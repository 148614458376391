import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getAuthToken } from 'utils/auth-singleton';
import LinearLayout from 'granite/components/LinearLayout';

const PublicOnlyRoute = ({ element: Component, layout, ...rest }) => {
  const authToken = getAuthToken();
  const location = useLocation();

  // Redirect logged-in users to the scheduling dashboard
  if (authToken) {
    return <Navigate to="/scheduling-dashboard" state={{ from: location }} />;
  }

  // Render the appropriate layout for non-authenticated users
  if (layout === 'PlainLayout') {
    return <Component />;
  }

  return (
    <LinearLayout>
      <Component />
    </LinearLayout>
  );
};

export default PublicOnlyRoute;
