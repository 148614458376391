import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import Singleton from 'utils/singleton';

import EventEmitter from 'utils/event-emitter';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import { fetchPtoPolicy } from 'pto/controllers/pto';
import { fetchCompanies, getBasicSettings } from 'company/controllers/company';

import { PTO_POLICY_EVENTS, TAGS_EVENTS } from 'pto/constants';
import { DEPARTMENT_EVENTS, TEAM_EVENTS, COMPANY_EVENTS, EMPLOYEE_EVENTS } from 'pages/shiftManagement/constants';

import { TOAST_MESSAGES, PTO_TABS } from 'pto/strings';
import { getDefaultStep } from 'pages/addOrEditPtoPolicy/helper/utils';

const eventEmitter = new EventEmitter();

const usePtoData = (params, query) => {
  const [loader, setLoader] = useState(true);
  const [policyData, setPolicyData] = useState({});
  const [tags, setTags] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dateFormat, setDateFormat] = useState('');
  const [dateFormatDisplay, setDateFormatDisplay] = useState('');
  const singleton = new Singleton();
  const policyId = params.id;
  const [activeStep, setActiveStep] = useState(getDefaultStep(query));
  const [policyType, setPolicyType] = useState('');

  const fetchPtoPolicyList = useCallback(() => {
    setLoader(true);
    fetchPtoPolicy(eventEmitter, {
      is_active: true,
      policyId,
      page_size: 5,
      page: 1,
    });
  }, [policyId]);

  const handleRefresh = useCallback(
    event => {
      if (event.data === 'REFRESH_ADD_EDIT_POLICY') {
        fetchPtoPolicyList();
      } else {
        setLoader(false);
      }
    },
    // eslint-disable-next-line no-use-before-define
    [fetchPtoPolicyList],
  );

  useEffect(() => {
    window.addEventListener('message', handleRefresh);
    return () => {
      window.removeEventListener('message', handleRefresh);
    };
  }, [handleRefresh]);

  useEffect(() => {
    getBasicSettings(eventEmitter, { authToken: query?.oAuthToken, company_id: +query.company_id });
    if (query.oAuthToken) {
      if (singleton.oAuthToken === null) {
        singleton.oAuthToken = query.oAuthToken;
      }
      if (policyId && policyId !== 'add') {
        fetchCompanies(eventEmitter, {
          paginate: false,
          company_id: +query.company_id,
        });
        fetchPtoPolicyList();
      } else {
        setLoader(false);
      }
    }
    // eslint-disable-next-line no-use-before-define
  }, [query, policyId, fetchPtoPolicyList]);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case DEPARTMENT_EVENTS.GET_DEPARTMENT_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.GET_DEPARTMENT_FAILURE_FALLBACK,
            });
            break;

          case TEAM_EVENTS.GET_TEAM_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.GET_TEAM_FAILURE_FALLBACK,
            });
            break;
          case TAGS_EVENTS.GET_TAGS_SUCCESS:
            setTags(event.data.tags);
            break;
          case TAGS_EVENTS.GET_TAGS_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.GET_TAGS_FAILURE_FALLBACK,
            });
            break;

          case EMPLOYEE_EVENTS.GET_EMPLOYEE_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.GET_EMPLOYEE_FAILURE_FALLBACK,
            });
            break;

          case PTO_POLICY_EVENTS.SHOW_LOADER:
            //  setLoader(true);
            break;
          case PTO_POLICY_EVENTS.HIDE_LOADER:
            //  setLoader(false);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS:
            setPolicyData(event?.data?.policies);
            setPolicyType(event?.data?.policies?.policy_type);
            setDisabled(
              event?.data?.policies?.employees?.length || event?.data?.policies?.is_active === false ? true : false,
            );
            setLoader(false);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.GET_PTO_POLICY_FAILURE_FALLBACK,
            });
            setLoader(false);
            break;

          case PTO_POLICY_EVENTS.CREATE_PTO_POLICY_SUCCESS:
            setPolicyData(event.data);
            setPolicyType(event?.data?.policy_type);
            GenericWindowPostMessage('SUCCESS_TOAST', {
              toastMessage: TOAST_MESSAGES.CREATE_PTO_POLICY_SUCCESS,
            });
            event?.data?.policy_type === 'PAID_HOLIDAYS'
              ? GenericWindowPostMessage(PTO_TABS.HOLIDAY_TAB, { policyId: event?.data?.id })
              : event?.data?.policy_type === 'UNPAID_TIME'
              ? GenericWindowPostMessage(PTO_TABS.EMPLOYEE_TAB, { policyId: event?.data?.id })
              : GenericWindowPostMessage(PTO_TABS.ACCURAL_TAB, { policyId: event?.data?.id });
            setSubmitting(false);
            break;
          case PTO_POLICY_EVENTS.CREATE_PTO_POLICY_FAILURE:
            setPolicyData([]);
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.CREATE_PTO_POLICY_FAILURE_FALLBACK,
            });
            setSubmitting(false);
            break;

          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            setDateFormat(event.data.dateFormat);
            setDateFormatDisplay(event.data.dateFormatDisplay);
            break;
          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.FAILURE_FALLBACK,
            });
            break;
          case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS:
            if (event.data.key === 0) {
              if (event?.data?.data?.policy_type === 'PAID_HOLIDAYS') {
                GenericWindowPostMessage(PTO_TABS.HOLIDAY_TAB, {
                  policyId: event?.data?.data?.id,
                });
                setActiveStep(1);
              }
              if (event?.data?.data?.policy_type === 'UNPAID_TIME') {
                GenericWindowPostMessage(PTO_TABS.EMPLOYEE_TAB, {
                  policyId: event?.data?.data?.id,
                });
                setActiveStep(3);
              } else {
                GenericWindowPostMessage(PTO_TABS.ACCURAL_TAB, {
                  policyId: event?.data?.data?.id,
                });
                setActiveStep(1);
              }
            } else if (event.data.key === 1) {
              if (event?.data?.data?.policy_type === 'PAID_HOLIDAYS') {
                break;
              } else {
                GenericWindowPostMessage(PTO_TABS.BALANCE_TAB, {
                  policyId: event?.data?.data?.id,
                });
                setActiveStep(2);
              }
            } else if (event.data.key === 2 && event?.data?.data?.policy_type !== 'PAID_HOLIDAYS') {
              GenericWindowPostMessage(PTO_TABS.EMPLOYEE_TAB, {
                policyId: event?.data?.data?.id,
              });
              setActiveStep(3);
            }
            setSubmitting(false);
            if (event.data.key === 0 || event.data.key === 1 || event.data.key === 2) {
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: TOAST_MESSAGES.UPDATE_PTO_POLICY_SUCCESS,
              });
            }
            break;
          case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || TOAST_MESSAGES.UPDATE_PTO_POLICY_FAILURE_FALLBACK,
            });
            setSubmitting(false);
            break;
          case PTO_POLICY_EVENTS.CHANGE_TABS:
            setPolicyType(event.data);
            break;
          default:
        }
      });
    }
    return () => subscription?.unsubscribe();
  }, []);

  return {
    loader,
    setLoader,
    policyData,
    activeStep,
    setActiveStep,
    policyId,
    submitting,
    setSubmitting,
    disabled,
    dateFormat,
    dateFormatDisplay,
    tags,
    eventEmitter,
    policyType,
  };
};

export default usePtoData;
