import React from 'react'
import {Box, Button, Select} from 'grommet'
import {Previous} from 'grommet-icons/icons/Previous'
import {Next} from 'grommet-icons/icons/Next'
import {useDataGridContext} from 'granite/components/DataGrid/DataGridTable'

function pagination(currentPage, nrOfPages) {
  let pages = [...Array(nrOfPages).keys()].map(item => item + 1)
  const first_start = 2
  const first_count = currentPage - first_start - 1
  if (first_count > 0) {
    pages.splice(first_start - 1, first_count, '...')
  }
  const second_start = currentPage + 2
  const second_count = nrOfPages - second_start
  if (second_count > 0) {
    const start_index = pages.indexOf(currentPage) + 2
    pages.splice(start_index, second_count, '...')
  }
  return pages
}

function DataGridPagination({
  pageData: {page = 1, size = 10, total},
  onPageChange,
  ...rest
}) {
  const totalPages = total % size
  const from = (page - 1) * size + 1
  let to = from + size - 1

  const {data} = useDataGridContext()
  if (data & (data.length < size)) {
    to = from + data.length - 1
  }

  const goToPrevPage = () => onPageChange({page: page - 1, size, total})
  const goToNextPage = () => onPageChange({page: page + 1, size, total})
  const goToPage = page => onPageChange({page: page, size, total})
  const changeSize = ev =>
    onPageChange({page: 1, size: parseInt(ev.value), total})

  return (
    <Box direction="row" justify="between">
      <Select
        options={['10', '20', '50', '100']}
        value={`${size}`}
        onChange={changeSize}
      />
      <Box direction="row">
        <span>
          {from}-{to} of {total}
        </span>
        <Box direction="row">
          <Button
            icon={<Previous />}
            onClick={goToPrevPage}
            as="span"
            disabled={page === 1}
          />
          {pagination(1, 20).map(item => (
            <Button
              key={item}
              active={item === page}
              as="span"
              onClick={() => goToPage(item)}
            >
              {item}
            </Button>
          ))}
          <Button
            icon={<Next />}
            onClick={goToNextPage}
            as="span"
            disabled={page === totalPages}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DataGridPagination
