import {legacy_createStore as createStore, applyMiddleware, compose} from 'redux'
import {createLogger} from 'redux-logger'

import reducer from 'utils/ducks'

const logger = createLogger({
  level: 'info',
  collapsed: true,
})

function configureStore(initialState = undefined) {
  const enhancers = compose(
    // Middleware store enhancer.
    applyMiddleware(logger),
  )

  const store = initialState
    ? createStore(reducer, initialState, enhancers)
    : createStore(reducer, enhancers)

  return store
}

export default configureStore
