// Lib imports
import React, {useState, useEffect, useCallback} from 'react'
import { useNavigate } from 'react-router-dom'
import {Box, Heading, Image} from 'grommet'

// Core imports
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'
import LOGIN_MESSAGES from 'messages/login'

// Application imports
import {loginClicked} from 'accounts/controllers/user'
import LoginForm from './components/LoginForm'
import LOGIN_EVENTS from './constants'

const Login = ({dispatch}) => {
  const eventEmitter = new EventEmitter()
  const defaultToastData = {open: false, message: ''}
  // Define/Connect state
  const [toastData, setToastData] = useState(defaultToastData)
  const navigate=useNavigate()
  // Internal utils methods
  const showToast = (message,color) => setToastData({open: true, message , background:color})
  const hideToast = () => setToastData({open: false, message: ''})
  const goToScheduling = useCallback(
    () => navigate('/scheduling-dashboard'),
    [],
  )

  /**
   * Effect: subscribe and listen to Rx events
   * Cleanup: unsubscribe to Rx observable
   * Deps: eventEmitter, goToDashboard
   */
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_SUCCESS:
          showToast(LOGIN_MESSAGES.LOGIN_SUCCESS)
          setTimeout(() => {
            hideToast()
            goToScheduling()
          }, 1000)
          break
        case LOGIN_EVENTS.LOGIN_FAILURE:
          showToast(event.data || LOGIN_MESSAGES.LOGIN_FAILURE, 'accent-2')
          setTimeout(() => {
            hideToast()
          }, 1000)
          break
        default:
          break
      }
    })
    return () => subscription.unsubscribe()
  }, [eventEmitter, goToScheduling])

  return (
    <Box align="center" background="light-2" fill="horizontal" justify="center" gap="medium" height={{"min": "100%"}}>
      <Box
        background="light-1"
        width="31%"
        animation="fadeIn"
        pad="15px"
        elevation="medium"
        round="small"
        height={{"min": "auto"}}
      >
        <Box align="center" pad={{bottom:"small"}} height={{"min":"auto"}}>
          <Image
            height="85"
            width="130"
            style={{'marginBottom':"2px"}}
            src={process.env.PUBLIC_URL + '/img/logo_blue.png'}
            alt="menu"
          />
           <Image
            height="26px"
            width="150px"
            src={process.env.PUBLIC_URL + '/img/brand_name.png'}
            alt="menu"
          />
        </Box>
        <Box margin={{left:"2.5em", right:"2.5em", top:"2.5em"}} pad={{bottom:'medium'}}>
          <Heading color="brand" level={4} margin={{bottom: 'small'}} style={{'fontWeight':'400'}}>
            {LOGIN_MESSAGES.HEADING}
          </Heading>
          <LoginForm loginClicked={loginClicked} eventEmitter={eventEmitter} />
        </Box>
      </Box>
      {toastData.open ? <Toast background={toastData.background} text={toastData.message} /> : null}
    </Box>
  )
}

export default Login
