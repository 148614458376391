import React from 'react';
import { Box } from 'grommet';
import styles from 'pages/style/styles.module.scss';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import BluePolicyIcon from 'pages/addOrEditPtoPolicy/icons/PolicyDetailsblue.png';
import BlackPolicyIcon from 'pages/addOrEditPtoPolicy/icons/PolicyDetailsBlack.png';
import PolicyIcon from 'pages/addOrEditPtoPolicy/icons/PolicyDetailsGrey.png';
import BlueAccuralIcon from 'pages/addOrEditPtoPolicy/icons/AccrualSettingBlue.png';
import BlackAccuralIcon from 'pages/addOrEditPtoPolicy/icons/AccrualSettingBlack.png';
import AccuralIcon from 'pages/addOrEditPtoPolicy/icons/AccrualSettingGrey.png';
import BlueBalanceIcon from 'pages/addOrEditPtoPolicy/icons/BalanceSettingBlue.png';
import BlackBalanceIcon from 'pages/addOrEditPtoPolicy/icons/BalanceSettingBlack.png';
import BalanceIcon from 'pages/addOrEditPtoPolicy/icons/BalanceSettingGrey.png';
import BlueEmployeeIcon from 'pages/addOrEditPtoPolicy/icons/EmployeeSelectionBlue.png';
import BlackEmployeeIcon from 'pages/addOrEditPtoPolicy/icons/EmployeeSelectionBlack.png';
import EmployeeIcon from 'pages/addOrEditPtoPolicy/icons/EmployeeSelectionGrey.png';
import Calender from 'pages/addOrEditPtoPolicy/icons/Calender.svg';
import CalenderActive from 'pages/addOrEditPtoPolicy/icons/CalenderActive.svg';
import CalenderDisabled from 'pages/addOrEditPtoPolicy/icons/CalenderDisabled.svg';
import { StyledProgressBarImage } from 'pages/addOrEditPtoPolicy/styles';
import { PTO_TABS } from 'pto/strings';

const PolicyProgressbar = ({ activeStep, setActiveStep, policyId, data, policyType }) => {
  const changesTab = steps => {
    if (policyId) {
      if (steps === 0) {
        setActiveStep(0);
        GenericWindowPostMessage(PTO_TABS.POLICY_TAB, { policyId: policyId });
      } else if (steps === 1) {

        setActiveStep(1);
        GenericWindowPostMessage(policyType === 'PAID_HOLIDAYS' ? PTO_TABS.HOLIDAY_TAB : PTO_TABS.ACCURAL_TAB, {
          policyId: policyId,
        });
      } else if (steps === 2) {
        setActiveStep(2);
        GenericWindowPostMessage(policyType === 'PAID_HOLIDAYS' ? PTO_TABS.EMPLOYEE_TAB : PTO_TABS.BALANCE_TAB, {
          policyId: policyId,
        });
      } else if (steps === 3) {
        setActiveStep(3);
        GenericWindowPostMessage(PTO_TABS.EMPLOYEE_TAB, { policyId: policyId });
      }
    }
  };

  return (
    <Box direction="row" role="group" justify="center">
      <button type="button" className={styles['progressBarborders']} onClick={() => changesTab(0)}>
        <StyledProgressBarImage
          src={activeStep === 0 ? BluePolicyIcon : !policyId ? PolicyIcon : BlackPolicyIcon}
          alt="Logo"
        />
        <span style={{ color: activeStep === 0 ? '#09819A' : !policyId ? '#B1BABF' : 'black' }}>Policy Details</span>
      </button>
      {policyType === 'PAID_HOLIDAYS' ? (
        <button disabled={!policyId} type="button" className={styles['progressBarEnd']} onClick={() => changesTab(1)}>
          <StyledProgressBarImage
            src={activeStep === 1 ? CalenderActive : !policyId ? CalenderDisabled : Calender}
            alt="Logo"
          />
          <span style={{ color: activeStep === 1 ? '#09819A' : !policyId ? '#B1BABF' : 'black' }}>
            Holiday Calender
          </span>
        </button>
      ) : (
        <>
          <button
            disabled={!policyId || (data && data.policy_type === 'UNPAID_TIME')}
            type="button"
            className={styles['progressBar']}
            onClick={() => changesTab(1)}
          >
            <StyledProgressBarImage
              src={
                activeStep === 1
                  ? BlueAccuralIcon
                  : !policyId || (data && data.policy_type === 'UNPAID_TIME')
                  ? AccuralIcon
                  : BlackAccuralIcon
              }
              alt="Logo"
            />
            <span
              style={{
                color:
                  activeStep === 1
                    ? '#09819A'
                    : !policyId || (data && data.policy_type === 'UNPAID_TIME')
                    ? '#B1BABF'
                    : 'black',
              }}
            >
              Accrual Settings
            </span>
          </button>
          <button
            disabled={!policyId || (data && data.policy_type === 'UNPAID_TIME')}
            type="button"
            className={styles['progressBar']}
            onClick={() => changesTab(2)}
          >
            <StyledProgressBarImage
              src={
                activeStep === 2
                  ? BlueBalanceIcon
                  : !policyId || (data && data.policy_type === 'UNPAID_TIME')
                  ? BalanceIcon
                  : BlackBalanceIcon
              }
              alt="Logo"
            />
            <span
              style={{
                color:
                  activeStep === 2
                    ? '#09819A'
                    : !policyId || (data && data.policy_type === 'UNPAID_TIME')
                    ? '#B1BABF'
                    : 'black',
              }}
            >
              Balance Settings
            </span>
          </button>
        </>
      )}
      <button
        disabled={!policyId}
        type="button"
        className={styles['progressBarEnd']}
        onClick={() => (policyType === 'PAID_HOLIDAYS' ? changesTab(2) : changesTab(3))}
      >
        <StyledProgressBarImage
          src={
            activeStep === (policyType === 'PAID_HOLIDAYS' ? 2 : 3)
              ? BlueEmployeeIcon
              : !policyId
              ? EmployeeIcon
              : BlackEmployeeIcon
          }
          alt="Logo"
        />
        <span
          style={{
            color:
              activeStep === (policyType === 'PAID_HOLIDAYS' ? 2 : 3) ? '#09819A' : !policyId ? '#B1BABF' : 'black',
          }}
        >
          Employee Selection
        </span>
      </button>
    </Box>
  );
};

export default PolicyProgressbar;
