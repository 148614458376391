import React from 'react';
import { Box, Button } from 'grommet';

import FilterDropdownComponent from 'pages/components/FilterDropdown';
import FilterComponent from './FilterComponent';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import 'pages/style/styles/index.global.scss';
const Filter = ({ status, applyFilter, setStatus, onStatusSelect, dateFormat, dates }) => {
  const handleStatusChange = status => {
    setStatus(status);
    onStatusSelect(status);
  };

  return (
    <Box direction="column">
      <Box
        //className="d-inline-block department-index department-dropdown"
        gap="small"
        margin={{ top: 'small' }}
        style={{ minWidth: 'small' }}
        pad={{ top: 'xsmall' }}
        direction="row"
      >
        <FilterDropdownComponent
          paramKey="value"
          options={[
            { value: '', label: 'All' },
            { value: 'PENDING', label: 'Pending' },
            { value: 'APPROVED', label: 'Approved' },
            { value: 'REJECTED', label: 'Rejected' },
          ]}
          onChange={val => handleStatusChange(val)}
          value={status}
          keyLabel={'value'}
          valueLabel={'label'}
        />
        <Button
          label="+ Add Time Off"
          color="accent-1"
          onClick={() => GenericWindowPostMessage('OPEN_ADD_TIME_OFF_SLIDER', {})}
          alignSelf="end"
          pad="xxsmall"
          primary
        />

        <Button
          label="View Time Off Policies"
          color="accent-1"
          onClick={() => GenericWindowPostMessage('OPEN_VIEW_TIME_OFF_POLICY_PAGE', {})}
          alignSelf="end"
          pad="xxsmall"
          primary
        />
      </Box>

      <FilterComponent dateFormat={dateFormat} applyFilter={applyFilter} dates={dates} />
    </Box>
  );
};

export default Filter;
