import React from 'react';

import LinearLayout from 'granite/components/LinearLayout';

import {getAuthToken} from 'utils/auth-singleton'

const PublicRoute = ({ element: Component, layout,...rest }) => {
  // Perform any auth-related logic if needed
  const authToken = getAuthToken();
  if (authToken) {
    // Call getProfile async, so that we can display profile in Header (if required)
  }

  // Render the appropriate layout
  if (layout === 'PlainLayout') {
    return <Component />;
  }

  return (
    <LinearLayout>
      <Component />
    </LinearLayout>
  );
};

export default PublicRoute;
