import React from 'react';
import { Box } from 'grommet';

import SectionLoaderAtom from 'atoms/SectionLoader';
import Steps from 'granite/components/Steps';
import PolicyProgressbar from 'pages/addOrEditPtoPolicy/component/PolicyProgreesBar';

import { getSteps } from 'pages/addOrEditPtoPolicy/helper/utils';

import usePtoData from 'pages/addOrEditPtoPolicy/hooks/usePtoData';
import withRouter from 'utils/withRouter'

const AddEditPolicyPage = ({ params, query }) => {
  const {
    loader,
    setLoader,
    policyData,
    activeStep,
    setActiveStep,
    policyId,
    submitting,
    setSubmitting,
    disabled,
    dateFormat,
    dateFormatDisplay,
    tags,
    eventEmitter,
    policyType
  } = usePtoData(params, query);

  return loader ? (
    <SectionLoaderAtom active />
  ) : (
    <Box background={'light-1'} pad="large" margin={{ left: 'medium', right: 'large', top: 'medium' }} flex="grow">
      <Box>
        <Box pad={{ left: 'xlarge', right: 'xlarge' }}>
          <PolicyProgressbar
            data={policyData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            policyId={policyId}
            policyType={policyType}
          />
        </Box>
        <Box pad={{ left: 'xlarge', right: 'xlarge' }}>
          <Steps
            margin="xsmall"
            activeStep={activeStep}
            steps={getSteps(
              eventEmitter,
              submitting,
              setSubmitting,
              disabled,
              setActiveStep,
              dateFormat,
              dateFormatDisplay,
              policyId,
              tags,
              policyData,
              setLoader,
              query,
              policyType
            )}
            navigationVisible={false}
            async
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(AddEditPolicyPage);
