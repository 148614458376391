import React from 'react';

import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import { Col } from 'reactstrap';import { FormGroup } from 'reactstrap';import { InputGroup } from 'reactstrap';import { Label } from 'reactstrap';
import {ReactComponent as CalendarIcon} from 'atoms/DateTimePicker/calendar.svg';

/**a
 * DateTimePickerAtom
 *
 * - For props refer: https://chmln.github.io/flatpickr/options/
 */
const DateTimePickerAtom = (props) => {
   const {
    field, layout, label, help,  options, prefix, suffix, id,disabled,
    getOptions, onChange, ...rest
  } = props;
  let pickerInstance;
  const handleChange = (val) => {
    const { form: { setFieldValue } } = props;      
    if (rest.mode === 'range' || rest.mode === 'multiple') {
      setFieldValue(field.name, val[0]);
      /*input.onChange(val || null);*/
      if (onChange) onChange(val[0] || null);
    } else {
      setFieldValue(field.name, val[0]);
      /*input.onChange(val[0] || null);*/
      if (onChange) onChange(val[0] || null);
    }
  };


  return (
    <FormGroup
      row={Object.keys(layout).length > 0}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0

        ? (<Col {...layout.wrapperCol}>
          <InputGroup>
            
            {prefix && (<div className= "input-group-addon">{prefix}</div>)}
            <Flatpickr
               disabled={disabled}
              {...rest}
              defaultValue={field.value}
              options={{
                minuteIncrement: 1,
                disableMobile: true,
                ...options,
              }}
              className="form-control"
              onChange={handleChange}
              ref={ref => {
                if (ref) pickerInstance = ref.flatpickr;
              }}
            />
            <div className= "input-group-addon cursor-pointer" onClick={() => setTimeout(function(){ pickerInstance.open(); }, 0)}>
              { <CalendarIcon width="20" height="20" />}
            </div>
          </InputGroup>
        </Col>)
        : (<span style={{ width: '100%'}}>
          <InputGroup>
            {prefix && (<div className= "input-group-addon">{prefix}</div>)}
            <Flatpickr
              {...rest}
              defaultValue={field.value}
              options={{
                minuteIncrement: 1,
                disableMobile: true,
                ...options,
              }}
              className="form-control"
              onChange={handleChange}
              ref={ref => {
                if (ref) pickerInstance = ref.flatpickr;
              }}
              disabled={disabled}
            />
           <div className= "input-group-addon cursor-pointer" onClick={() => setTimeout(function(){ pickerInstance.open(); }, 0)} disabled={disabled}> 
              { <CalendarIcon width="20" height="22" />}
            </div>
          </InputGroup>
        </span>)
      }
    </FormGroup>
  );
};

DateTimePickerAtom.defaultProps = {
  layout: {},
};

DateTimePickerAtom.propTypes = {
  help: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  layout: PropTypes.object,
  options: PropTypes.object,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
};

export default DateTimePickerAtom;
