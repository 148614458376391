import React from 'react'

import PropTypes from 'prop-types'

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';


import  './index.modules.styles.scss'

/**
 * FilterDropdownComponent
 *
 * Props:
 *    - location
 */

const FilterDropdownComponent = (props, context) => {
  const {
    paramKey,
    options,
    size,
    tether,
    header,
    onChange,
    valueLabel,
    keyLabel,
    value,
    cookieKey,
    disabled,
  } = props

  const filteredOption = options && options.filter(item => value === item.value)
  const submit = data => {
    onChange(data)
  }

  return (
    <UncontrolledDropdown size={size} tether={tether}>
      <div  className="dropdown-override pr-1 dropdown-toggle.btn-accent">
        <DropdownToggle
          caret
          color="accent"
          disabled={disabled}
          title={
            (filteredOption.length != 0 && filteredOption[0].label) ||
            (options[0] && options[0][valueLabel])
          }
        >
          {(filteredOption?.length !== 0 && filteredOption[0].label) ||
            (options[0] && options[0][valueLabel])}
        </DropdownToggle>
      </div>
      <DropdownMenu  className="drop-item filterDropDown" >
        {header ? (
          <span>
            <DropdownItem header>{header}</DropdownItem>
            <DropdownItem divider />
            {options.map(item => (
              <DropdownItem
                title={item[valueLabel]}
                key={item[keyLabel]}
                onClick={() => submit(item[keyLabel])}
              >
                {item[valueLabel]}
              </DropdownItem>
            ))}
          </span>
        ) : (
          <span>
            {options.map(item => (
              <DropdownItem
                title={item[valueLabel]}
                key={item[keyLabel]}
                onClick={() => submit(item[keyLabel])}
              >
                {item[valueLabel]}
              </DropdownItem>
            ))}
          </span>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

FilterDropdownComponent.defaultProps = {
  options: [{value: '', label: 'Select'}],
  keyLabel: 'value',
  valueLabel: 'label',
}

FilterDropdownComponent.propTypes = {
   paramKey: PropTypes.string.isRequired,
 // location: PropTypes.object.isRequired,
  options: PropTypes.array,
  size: PropTypes.string,
  tether: PropTypes.object,
  onChange: PropTypes.func,
}


export default FilterDropdownComponent

