// Lib Imports
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {Box, Heading, Text} from 'grommet'

import EventEmitter from 'utils/event-emitter'
import RESET_MESSAGES from 'messages/forgotPassword'
import Steps from 'granite/components/Steps'
import Toast from 'granite/components/Toast'

// Application Imports
import RequestTokenForm from './components/RequestTokenForm'
import SubmitTokenForm from './components/SubmitTokenForm'
import ResetPasswordForm from './components/ResetPasswordForm'
import {
  resetPasswordClicked,
  requestTokenClicked,
  submitTokenClicked,
} from 'accounts/controllers/user'
import RESET_EVENTS from './constants'

const headings = ['Forgot Password?', 'Verification', 'Reset Password']
const subHeadings = [
  'Enter your registered Phone number or Email to reset the password',
  'The one time password (OTP) was sent',
]
const captions = ['We will send you a one time password (OTP)']

function listenEvents({
  eventEmitter,
  setToastData,
  setActiveStep,
  setUid,
  navigate
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case RESET_EVENTS.RESET_PASSWORD_SUCCESS:
        // *
        //  * NOTES:-
        //  *  - Page level logic are part of Page only.
        //  *  - LoginForm component only responsible for Login,
        //  *    not for what happend after Login - show toast or navigate
        //  *  - LoginForm is not responsible for in what layout
        //  *    that form component is being used.
        //  *  - Page dispatches Redux actions, navigates routes
        setToastData({
          open: true,
          message: RESET_EVENTS.RESET_PASSWORD_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
          navigate('/login')
        }, 1000)
        /* dispatch({
              type: 'accounts/user/UPDATE_PROFILE',
              data: event.data,
            }) */
        break
      case RESET_EVENTS.RESET_PASSWORD_FAILURE:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_PASSWORD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        break
      case RESET_EVENTS.RESET_TOKEN_SUCCESS:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_TOKEN_SUCCESS,
        })
        setTimeout(() => {
          setActiveStep(2)
          setUid(event.data.uid)
          setToastData({open: false, message: ''})
        }, 1000)
        break
      case RESET_EVENTS.RESET_TOKEN_FAILURE:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_TOKEN_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        break
      case RESET_EVENTS.RESET_SUCCESS:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_SUCCESS,
        })
        setTimeout(() => {
          setActiveStep(1)
          setToastData({open: false, message: ''})
        }, 1000)
        break
      case RESET_EVENTS.RESET_FAILURE:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        break
      default:
        break
    }
  })
  return subsciption
}

const ResetPassword = ({dispatch}) => {
  let eventEmitter = new EventEmitter()
  const defaultActiveStep = 0
  const [uid, setUid] = useState()
  const [activeStep, setActiveStep] = useState(defaultActiveStep)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [emailOrPhone, setEmailOrPhone] = useState({email: undefined})
  const navigate = useNavigate()

  const getSteps = () => {
    const onResendToken = () => {
      reqTokenFormOnSubmit(
        {email: emailOrPhone},
        {
          setErrors: () => null,
          setSubmitting: () => null,
        },
      )
    }
    const reqTokenFormOnSubmit = async (values, {setSubmitting, setErrors}) => {
      setErrors({})
      setSubmitting(true)
      try {
        await requestTokenClicked(values)
        // storing emailOrPhone of user to allow resend token
        setEmailOrPhone(values.email)
        eventEmitter.emit(RESET_EVENTS.RESET_SUCCESS)
      } catch (e) {
        eventEmitter.emit(RESET_EVENTS.RESET_FAILURE)
        if (e.errors) setErrors(e.errors)
      }
      setSubmitting(false)
    }

    const submitTokenFormOnSubmit = async (
      values,
      {setSubmitting, setErrors},
    ) => {
      setErrors({})
      setSubmitting(true)
      try {
        const uid = await submitTokenClicked(values)
        eventEmitter.emit(RESET_EVENTS.RESET_TOKEN_SUCCESS, {uid})
      } catch (e) {
        eventEmitter.emit(RESET_EVENTS.RESET_TOKEN_FAILURE)
        if (e.errors) setErrors(e.errors)
      }
      setSubmitting(false)
    }

    const resetPasswordFormOnSubmit = async (
      values,
      {setSubmitting, setErrors},
    ) => {
      setErrors({})
      setSubmitting(true)
      try {
        await resetPasswordClicked({...values, uid: uid})
        eventEmitter.emit(RESET_EVENTS.RESET_PASSWORD_SUCCESS)
      } catch (e) {
        eventEmitter.emit(RESET_EVENTS.RESET_PASSWORD_FAILURE)
        if (e.errors) setErrors(e.errors)
      }
      setSubmitting(false)
    }
    return [
      {
        content: <RequestTokenForm onSubmit={reqTokenFormOnSubmit} />,
        icon: <></>,
      },
      {
        content: (
          <SubmitTokenForm
            onSubmit={submitTokenFormOnSubmit}
            onResendToken={onResendToken}
          />
        ),
        icon: <></>,
      },
      {
        content: <ResetPasswordForm onSubmit={resetPasswordFormOnSubmit} />,
        icon: <></>,
      },
    ]
  }

  useEffect(function init() {
    const subscription = listenEvents({
      eventEmitter,
      setToastData,
      setActiveStep,
      setUid,
      navigate
    })
    return () => subscription.unsubscribe()
  })

  return (
    <>
      <Box
        align="center"
        background="brand-3p"
        flex="grow"
        justify="center"
        margin="none"
        pad="medium"
      >
        <Box width="large" animation="fadeIn" pad="medium">
          <Heading color="dark-1" level={1} margin={{bottom: 'small'}}>
            {headings[activeStep]}
          </Heading>
          <Heading level={2} margin={{bottom: 'large'}}>
            {subHeadings[activeStep]}
          </Heading>
          <Text size="medium">{captions[activeStep]}</Text>
          <Steps
            margin="xsmall"
            steps={getSteps()}
            activeStep={activeStep}
            onChange={() => false}
            navigationVisible={false}
          />
        </Box>
        {toastData.open ? <Toast text={toastData.message} /> : null}
      </Box>
    </>
  )
}

export default ResetPassword
