import React, { useState, useEffect } from 'react';

import { Box } from 'grommet';

import FilterDropdownComponent from 'pages/components/FilterDropdown';
import FilterComponent from './FilterComponent';

import 'pages/style/styles/index.global.scss';

const Filter = ({
  defaultTeams,
  departments,
  setSelectedDepartments,
  setSelectedTeams,
  applyFilter,
  setStatus,
  status,
  selectedDepartments,
  selectedTeams,
  onDepartmentSelect,
  onTeamSelect,
  onStatusSelect,
  dateFormat,
}) => {
  const [defaultDepartments, setDepartments] = useState();
  const [teams, setTeams] = useState();

  useEffect(() => {
    let teamsList = [];
    let departmentsList = [];
    teamsList =
      defaultTeams &&
      defaultTeams.map(element => ({
        value: element.id,
        label: element.name,
      }));
    departmentsList =
      departments &&
      departments.map(element => ({
        value: element.id,
        label: element.name,
      }));
    teamsList.splice(0, 0, { value: '', label: 'All Teams' });
    departmentsList.splice(0, 0, { value: '', label: 'All Departments' });
    setTeams(teamsList);
    setDepartments(departmentsList);
  }, [defaultTeams, departments]);

  const handleDepartmentChange = departmentId => {
    setSelectedDepartments(departmentId);
    onDepartmentSelect(departmentId);
    setSelectedTeams('');
  };

  const handleTeamChange = teamId => {
    setSelectedTeams(teamId);
    onTeamSelect(teamId);
  };

  const handleStatusChange = status => {
    setStatus(status);
    onStatusSelect(status);
  };

  return (
    <Box direction="column">
      <Box direction="row" gap="xsmall">
        <Box id="department" className="d-inline-block department-index department-dropdown">
          <FilterDropdownComponent
            paramKey="department_id"
            value={selectedDepartments}
            options={defaultDepartments ?? []}
            onChange={val => handleDepartmentChange(val)}
            keyLabel={'value'}
            valueLabel={'label'}
          />
        </Box>

        <Box id="team" className="d-inline-block department-dropdown">
          <FilterDropdownComponent
            paramKey="team_id"
            value={selectedTeams}
            options={teams ?? []}
            onChange={val => handleTeamChange(val)}
            keyLabel={'value'}
            valueLabel={'label'}
          />
        </Box>

        <Box id="department" className="d-inline-block department-index department-dropdown">
          <FilterDropdownComponent
            paramKey="value"
            options={[
              { value: 'ALL', label: 'All' },
              { value: 'PENDING', label: 'Pending' },
              { value: 'APPROVED', label: 'Approved' },
              { value: 'REJECTED', label: 'Rejected' },
              { value: 'DELETED', label: 'Deleted' },
            ]}
            onChange={val => handleStatusChange(val)}
            value={status}
            keyLabel={'value'}
            valueLabel={'label'}
          />
        </Box>
      </Box>

      <FilterComponent dateFormat={dateFormat} applyFilter={applyFilter} />
    </Box>
  );
};

export default Filter;
