import React from 'react'
import {connect} from 'react-redux'
import { Navigate, useLocation} from 'react-router-dom'
import {Box} from 'grommet'

import userAPIGateway from 'accounts/gateways/user-api'
import * as UserDucks from 'accounts/ducks/user'
import {getAuthToken} from 'utils/auth-singleton'
import DashboardLayout from 'granite/components/DashboardLayout/index'
import LinearLayout from 'granite/components/LinearLayout'
import Spinner from 'granite/components/Spinner'

function fetchUserProfile(dispatch) {
  userAPIGateway.myProfile().then(response => {
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: response.organisations,
    })
    dispatch({
      type: 'organisations/UPDATE_SELECTED_ORGANISATION',
      data: response.organisations[0],
    })
    dispatch({
      type: 'accounts/user/UPDATE_PROFILE',
      data: response.user,
    })
  })
  return (
    <Box align="center" height="100%" justify="center">
      <Spinner />
    </Box>
  )
}

const PrivateRoute = ({
  element: Component,
  layout,
  roles,
  userProfile,
  dispatch,
  ...rest
}) => {
  const authToken = getAuthToken()
  const location = useLocation();

  //TODO: add two more checks so that spinner is shown while organisation and selectedOrganisation are being set
  if (authToken && !userProfile.username) {
    return fetchUserProfile(dispatch)
  }
  if (!authToken || !userProfile.username) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Role-based authorization check (if roles are provided)
  if (roles && !roles.includes(userProfile.role)) {
    return <Navigate to="/" />;
  }

  // Render the appropriate layout
  if (layout === 'HeaderLayout') {
    return (
      <LinearLayout pathname={location.pathname}>
        <Component />
      </LinearLayout>
    );
  }

  if (layout === 'PlainLayout') {
    return <Component />;
  }

  return (
    <DashboardLayout pathname={location.pathname}>
      <Component />
    </DashboardLayout>
  );
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute)
