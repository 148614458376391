import React from 'react';
import { Box, Button } from 'grommet';
import { Formik, Field } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import ErrorMessage from 'pages/components/PTO/ErrorMessage';

import DateTimePickerAtom from 'generics/DateTimePickerFormik';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

const FilterComponent = ({ applyFilter, dateFormat }) => {
  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required('Select Start Date'),
    end_date: Yup.date().required('Select End Date'),
  });
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const formattedStartDate = moment(values.start_date).format();
    const formattedEndDate = moment(values.end_date).format();
    if (formattedStartDate > formattedEndDate)
      return setErrors({ start_date: 'Start date should be less than end date.' });

    setSubmitting(true);
    applyFilter(values);
  };

  return (
    <Formik
      initialValues={{
        start_date: '',
        end_date: '',
        status_type: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Box direction="row-responsive" gap="medium">
            <Box gap="medium" direction="row" overflow="false">
              <ErrorMessage error={touched.start_date && errors.start_date}>
                <Field
                  id="start_date"
                  name="start_date"
                  placeholder="Start Date"
                  options={{ dateFormat }}
                  component={DateTimePickerAtom}
                />
              </ErrorMessage>
              <ErrorMessage error={touched.end_date && errors.end_date}>
                <Field
                  id="end_date"
                  name="end_date"
                  placeholder="End Date"
                  component={DateTimePickerAtom}
                  options={{ dateFormat }}
                />
              </ErrorMessage>
            </Box>
            <Box gap="medium" direction="row" overflow="false">
              <ErrorMessage>
                <Button
                  label="Apply"
                  color="accent-1"
                  pad={{ horizontal: 'medium', vertical: 'small' }}
                  type="submit"
                  alignSelf="end"
                  primary
                />
              </ErrorMessage>
              <ErrorMessage>
                <Button
                  label="+ Add Time Off"
                  color="accent-1"
                  onClick={() => {
                    GenericWindowPostMessage('OPEN_ADD_TIME_OFF_SLIDER', {});
                  }}
                  alignSelf="end"
                  pad={{ horizontal: 'medium', vertical: 'small' }}
                  primary
                />
              </ErrorMessage>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default FilterComponent;
